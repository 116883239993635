<template>
  <div id="user-view" class="" v-if="!loading">
    <v-row class="">
      <v-col cols="12" md="5">
        <ProfileBioPanel></ProfileBioPanel>
        <br />
        <v-card class="d-flex flex-column justify-center align-center">
          <v-card-text class="d-flex flex-column">
            <h2 class="text-xl font-weight-semibold mb-2">
              {{ $t("Ridery Contact") }}
            </h2>
            <v-divider></v-divider>
            <v-list>
              <v-list-item dense class="px-0 d-flex flex-column align-start">
                <span class="font-weight-medium me-2">{{ $t("Email") }}:</span>
                <span class="text--secondary"
                  ><a :href="`mailto:corporativo@ridery.app`" target="_blank"
                    >corporativo@ridery.app</a
                  ></span
                >
              </v-list-item>
              <v-list-item
                dense
                class="
                  px-0
                  d-flex
                  flex-column
                  align-start
                  margin-negative-profile
                "
              >
                <span class="font-weight-medium me-2"
                  >{{ $t("Telephone") }}:</span
                >
                <span class="text--secondary"
                  ><a href="tel:+584129344180" target="_blank"
                    >+584129344180</a
                  ></span
                >
              </v-list-item>
              <v-list-item
                dense
                class="
                  px-0
                  mb-n2
                  d-flex
                  flex-column
                  align-start
                  margin-negative-profile
                "
              >
                <span class="font-weight-medium me-2"
                  ><a></a>{{ $t("Monitoreo (Viajes) Teléfono") }}:</span
                >
                <span class="text--secondary"
                  ><a
                    :href="`https://api.whatsapp.com/send?phone=${admin_phone}`"
                    target="_blank"
                    >{{ admin_phone }}</a
                  ></span
                >
              </v-list-item>
              <v-list-item
                dense
                class="
                  px-0
                  mb-n2
                  d-flex
                  flex-column
                  align-start
                  margin-negative-profile
                "
              >
                <span class="font-weight-medium me-2"
                  ><a></a
                  >{{ $t("Monitoreo (Viajes) Correo electrónico") }}:</span
                >
                <span class="text--secondary"
                  ><a :href="`mailto:${admin_email}`" target="_blank">{{
                    admin_email
                  }}</a></span
                >
              </v-list-item>
              <v-list-item
                dense
                class="
                  px-0
                  mb-n2
                  d-flex
                  flex-column
                  align-start
                  margin-negative-profile
                "
              >
                <span class="font-weight-medium me-2"
                  ><a></a>Portal legacy:</span
                >
                <span
                  class="text--secondary text-decoration-underline"
                  @click="handleOuter()"
                  ><a class="text-underline">{{ $t("Link") }}</a></span
                >
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        <br />
        <v-card class="d-flex flex-column justify-center align-center">
          <v-card-text>
            <v-btn
              class=""
              color="secondary"
              block
              outlined
              @click="$router.go(-1)"
            >
              <v-icon>
                {{ icons.mdiArrowLeft }}
              </v-icon>
              <span class="ml-2">{{ $t("GoBack") }}</span>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" md="7" class="" v-if="!false">
        <v-tabs v-model="userTab" show-arrows class="user-tabs">
          <v-tab>
            <v-icon size="20" class="me-3">
              {{ tabs[0].icon }}
            </v-icon>
            <span>{{ $t(tabs[0].title) }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item class="">
            <v-container class="mt-12" id="faq">
              <!-- <v-row align="center" justify="center">
        <v-col cols="11">
          <h1>{{ $t("FAQ2") }}:</h1>
        </v-col>
      </v-row> -->
              <v-row justify="center">
                <v-col cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        :class="!$vuetify.theme.isDark && 'color-blue-text'"
                      >
                        {{
                          $t(
                            "Can I book a trip or make reservations for another person?"
                          )
                        }}
                        <template v-slot:actions>
                          <v-icon color="teal"> {{ icons.mdiPlus }}</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        {{
                          $t(
                            "Yes! When requesting a trip through the Corporate Panel, select ''Non-Corporate User'', fill in the fields, you proceed to request the trip and that's it, your rider will arrive right away."
                          )
                        }}
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header
                        :class="!$vuetify.theme.isDark && 'color-blue-text'"
                      >
                        {{
                          $t("How to request the invoice prior to recharging?")
                        }}
                        <template v-slot:actions>
                          <v-icon color="teal"> {{ icons.mdiPlus }}</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        {{
                          $t(
                            "Contact corporativo@ridery.app requesting the invoice of the recharge previously made, or failing that, to the fill out the payment form select ''YES'' in the field of invoice."
                          )
                        }}
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header
                        :class="!$vuetify.theme.isDark && 'color-blue-text'"
                      >
                        {{
                          $t(
                            "How does Ridery take care of the safety of its passengers?"
                          )
                        }}
                        <template v-slot:actions>
                          <v-icon color="teal"> {{ icons.mdiPlus }}</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        {{
                          $t(
                            "Our drivers must pass a selection process of 4 stages:"
                          )
                        }}
                        <br />
                        {{
                          $t(
                            "Psycho-technical evaluation, vehicle certification (aesthetics and mechanical), up-to-date legal documentation and final exam of app approval"
                          )
                        }}. <br />
                        {{
                          $t(
                            "Additionally, our monitoring team is always at willingness to deal with any incident"
                          )
                        }}
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header
                        :class="!$vuetify.theme.isDark && 'color-blue-text'"
                      >
                        {{
                          $t(
                            "What should I do if I file an incident with my app?"
                          )
                        }}
                        <template v-slot:actions>
                          <v-icon color="teal"> {{ icons.mdiPlus }}</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        {{
                          $t(
                            "Our operators are 24 hours for you! Communicate for the following means for any incident or inconvenience presented:"
                          )
                        }}<br />
                        Whatsapp:+584128835418 <br />
                        {{ $t("Email") }}: viajes@ridery.app
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header
                        :class="!$vuetify.theme.isDark && 'color-blue-text'"
                      >
                        {{ $t("How is a trip's fare calculated?") }}
                        <template v-slot:actions>
                          <v-icon color="teal"> {{ icons.mdiPlus }}</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        {{
                          $t(
                            "Our trips are calculated based on a fixed rate per fleet selected being able to have various surcharges (time of day, traffic conditions, etc) if applicable."
                          )
                        }}
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <br />
              <v-row class="d-flex flex-row justify-center mt-16">
                <RideBotCard />
              </v-row> </v-container
          ></v-tab-item>

          <!-- <v-tab-item>
            Tabla paginada con movimientos de saldo del usuario
          </v-tab-item>
          <v-tab-item> Notificaciones </v-tab-item>
          <v-tab-item> 4 </v-tab-item>
          <v-tab-item> 5 </v-tab-item> -->
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import {
  mdiArrowLeft,
  mdiCurrencyUsd,
  mdiFrequentlyAskedQuestions,
  mdiPlus,
} from "@mdi/js";
import { mapActions, mapState } from "vuex";
import axios from "@/plugins/axios";
import ProfileBioPanel from "./parts/ProfileBioPanel.vue";
import tripWhite from "@/assets/Trip_white.svg";
import tripDark from "@/assets/Trip_dark.svg";
import RideBotCard from "@/components/RideBotCard";
export default {
  components: {
    ProfileBioPanel,
    RideBotCard,
  },
  data() {
    return {
      icons: {
        mdiArrowLeft,
        tripWhite,
        tripDark,
        mdiCurrencyUsd,
        mdiFrequentlyAskedQuestions,
        mdiPlus,
      },
      trips: {},
      provider: false,
      userTab: null,
      tabs: [
        { icon: mdiFrequentlyAskedQuestions, title: "FAQ" },
        { icon: mdiCurrencyUsd, title: "Movimientos" },
      ],
    };
  },
  methods: {
    ...mapActions("auth", ["meNotLoading"]),
    handleOuter() {
      window.open("https://admin.ridery.app/corporate_login", "_blank").focus();
    },
  },
  computed: {
    ...mapState(["loading"]),
    ...mapState("auth", ["admin_email", "admin_phone"]),
  },
  created() {
    try {
      this.meNotLoading();
    } catch (error) {
      this.$dialog.notify.error(error.message);
    }
  },
};
</script>
<style lang="scss">
#user-view {
  .user-tabs {
    &.v-tabs:not(.v-tabs--vertical) {
      box-shadow: none !important;
      .v-tabs-bar {
        background-color: transparent !important;
      }
    }
  }
  .margin-negative-profile {
    margin-top: -30px;
  }

  // tab content
  #user-tabs-content {
    background-color: transparent;
  }
}
</style>
