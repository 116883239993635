<template>
  <v-col cols="12" :md="columns">
    <v-card class="triangle-card">
      <v-row>
        <v-col cols="10">
          <v-card-title class="text-no-wrap pt-2">
            <h2>RideBot 🤖</h2>
          </v-card-title>
          <v-card-text class="pb-2" v-if="$vuetify.lang.current === 'es'">
            <p>¿Alguna <b>inquietud</b>?</p>
            <p>
              <b
                ><a
                  class="text-decoration-underline"
                  href="https://api.whatsapp.com/send?phone=584128835418&text=Soy%20un%20cliente%20corporativo.%F0%9F%91%A9%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BC%F0%9F%91%A8%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BC"
                  target="_blank"
                  >Comunícate</a
                ></b
              >
              través de nuestro bot de <b>Whatsapp</b>
            </p>
            <p class="text-xs">
              <b>
                <a
                  class="text-decoration-underline"
                  href="https://api.whatsapp.com/send?phone=584128835418&text=Soy%20un%20cliente%20corporativo.%F0%9F%91%A9%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BC%F0%9F%91%A8%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BC"
                  target="_blank"
                >
                  +58(412)883-54-18
                </a>
              </b>
            </p>
          </v-card-text>
          <v-card-text class="pb-2" v-else>
            <p>Do you have an <b>issue</b>?</p>
            <p>
              <b
                ><a
                  class="text-decoration-underline"
                  href="https://api.whatsapp.com/send?phone=584128835418&text=Soy%20un%20cliente%20corporativo.%F0%9F%91%A9%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BC%F0%9F%91%A8%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BC"
                  target="_blank"
                  >Chat</a
                ></b
              >
              with us through our <b>Whatsapp</b> bot
            </p>
            <p class="text-xs">
              <b>
                <a
                  class="text-decoration-underline"
                  href="https://api.whatsapp.com/send?phone=584128835418&text=Soy%20un%20cliente%20corporativo.%F0%9F%91%A9%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BC%F0%9F%91%A8%F0%9F%8F%BB%E2%80%8D%F0%9F%92%BC"
                  target="_blank"
                >
                  +58(412)883-54-18
                </a>
              </b>
            </p>
          </v-card-text>
        </v-col>
        <v-col cols="4">
          <v-img
            contain
            width="166px"
            height="190px"
            :src="
              require(`@/assets/images/misc/triangle-${
                $vuetify.theme.dark ? 'dark' : 'light'
              }.png`)
            "
            class="triangle-bg"
          ></v-img>
          <!-- class="ms-6" -->
          <v-img
            :class="'rideBot'"
            :max-height="$vuetify.breakpoint.mdAndUp ? 250 : 200"
            :max-width="$vuetify.breakpoint.mdAndUp ? 250 : 200"
            src="@/assets/Todo-good-RideBot.png"
          ></v-img>
          <!-- class="pb-16 mr-16" -->
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>
<script>
export default {
  name: "RideBotCard",
  props: {
    columns: {
      type: Number,
      default: 7,
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";
.triangle-card {
  position: relative;
  .triangle-bg {
    position: absolute;
    bottom: 0;
    @include ltr() {
      right: 0;
    }
    @include rtl() {
      left: 0;
    }
  }
  .rideBot {
    position: absolute;
    bottom: 5px;
    @include ltr() {
      right: -85px;
    }
    @include rtl() {
      left: 0;
    }
  }
  .gamification-trophy {
    position: absolute;
    bottom: 10%;
    @include ltr() {
      right: 8%;
    }
    @include rtl() {
      left: 8%;
    }
  }
}
.gamification-tree-4,
.gamification-john-pose-2,
.gamification-tree {
  position: absolute;
}
.gamification-tree {
  top: 10%;
  @include ltr() {
    right: 0;
  }
  @include rtl() {
    left: 0;
  }
}
.gamification-john-pose-2 {
  bottom: 0;
  @include ltr() {
    right: 15%;
  }
  @include rtl() {
    left: 15%;
  }
}
.gamification-tree-4 {
  bottom: 0;
  @include ltr() {
    right: 30%;
  }
  @include rtl() {
    left: 35%;
  }
}
@media (max-width: 600px) {
  .gamification-tree,
  .gamification-tree-4 {
    display: none;
  }
  .gamification-john-pose-2 {
    @include ltr() {
      right: 5%;
    }
    @include rtl() {
      left: 5%;
    }
  }
}
@media (max-width: 500px) {
  .gamification-john-pose-2 {
    max-width: 70px;
  }
}
@media (max-width: 400px) {
  .page-title {
    font-size: 1.2rem !important;
  }
}
</style>
